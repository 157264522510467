<template>
  <v-container class="pa-0 ma-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-card elevation="10">
          <v-toolbar
            dark
            dense
            color="transparent"
            flat
          >
            <v-toolbar-title dense>
              <h3 class="teal--text">
                {{ titulo }}
              </h3>
            </v-toolbar-title>
            <v-spacer />

            <!-- <v-btn
              align="right"
              color="info"
              dark
              small
              class="ma-1"
              @click="regresar"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn> -->
          </v-toolbar>

          <v-divider />

          <v-row class="mt-1">
            <v-col cols="6">
              <v-card
                color="teal lighten-4"
                class="rounded-lg mx-2"
              >
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card-text>
                    <v-row justify="center">
                      <h2 class="ma-2">
                        Contacto
                      </h2>
                    </v-row>
                    <leecontacto class="mb-4" />

                    <v-text-field
                      v-model="empresa"
                      prepend-inner-icon="work"
                      label="Empresa:"
                      dense
                      color="teal"
                    />

                    <v-text-field
                      v-model="celular"
                      prepend-inner-icon="mdi-cellphone"
                      label="Celular"
                      :counter="10"
                      dense
                      :rules="celularRules"
                    />

                    <v-text-field
                      v-model="telefono"
                      dense
                      prepend-inner-icon="mdi-phone"
                      :counter="10"
                      label="Teléfono Oficina"
                    />

                    <v-text-field
                      v-model="email"
                      dense
                      label="Email"
                      prepend-inner-icon="mdi-email"
                    />

                    <v-text-field
                      v-model="sucursal"
                      dense
                      prepend-inner-icon="mdi-office"
                      label="Sucursal"
                    />

                    <v-text-field
                      v-model="departamento"
                      hide-details
                      dense
                      prepend-inner-icon="mdi-account-group"
                      label="Departamento"
                    />
                  </v-card-text>
                  <leecliente />
                </v-form>

                <v-row>
                  <v-spacer />
                  <v-btn
                    color="success"
                    dark
                    small
                    align="center"
                    class="ma-2"
                    @click="agregar"
                  >
                    <v-icon class="mr-2">
                      mdi-check-circle
                    </v-icon>
                    PROCESAR
                  </v-btn>
                  <v-spacer />
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="5">
              <v-card flat>
                <Histcont />
              </v-card>

              <v-img
                src="@/assets/contacto1.png"
                contain
                height="250"
                class="ml-12"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-row>
        <v-toolbar
          color="teal"
          dense
          height="15"
        />
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Histcont from '@/views/crm/contactos/histcont'
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'

export default {
  components: {
    leecontacto,
    Histcont,
    leecliente
  },

  data() {
    return {
      valid: true,
      loading: false,
      //CONTACTO
      nomcont: '',
      telefono: '',
      // telefonoRules: [
      //   v => !!v || 'El teléfono es requerido.',
      //   v => (v && v.length <= 10) || 'Máximo 10 caracteres'
      // ],
      celularRules: [v => (v && v.length <= 10) || 'Máximo 10 caracteres'],
      empresa: '',
      email: '',
      celular: '',
      sucursal: '',
      departamento: '',
      idcontacto: 0,
      numcli: '',
      nModo: 1,
      cliente: {}
    }
  },

  computed: {
    ...mapGetters('contactos', ['getContacto', 'getNewContacto']),
    ...mapGetters('clientes', ['getCliente', 'getNewCliente']),

    validaInfo() {
      this.getNewContacto

      // let lvalida = this.validate()
      // console.log('lvalida', lvalida)
      console.log('nomcont', this.nomcont)

      // if (lvalida == false) {
      // return
      // }

      return (
        this.nomcont !== '' &&
        this.empresa !== '' &&
        this.telefono !== '' &&
        this.email !== ''
      )
    }
  },

  watch: {
    getContacto() {
      // this.limpiar()
      this.actualizarDatoscontactos()
    },

    getNewContacto() {
      this.actualizarNomcont()
    }
  },

  created() {
    // this.idcita = ''
    // console.log('router', this.$route.params.contacto)
    let ruta = this.$route.params.contacto
    // this.idcontacto = this.$route.params.contacto.idcontacto

    //MODO EDITAR
    if (ruta !== undefined) {
      this.nModo = 2
      this.readonly = true

      this.CargarInfo(this.$route.params.contacto)
      this.modo = 'Editar'
      this.titulo = 'Editar contacto'
    } else {
      // console.log("nuevo")
      //VALORES INICIALES ALTA
      this.nModo = 1
      this.modo = 'Nuevo'
      this.titulo = 'Nuevo contacto'
    }

    // console.log("nmodo", this.nModo)
  },

  beforeDestroy() {
    // console.log("SALIR beforeDestroy")
    this.setLimpiarContacto()

    //CLIENTES
    this.setLimpiarCliente()
  },

  methods: {
    ...mapActions('snackbar', ['showSnack']),
    ...mapActions('clientes', [
      'setCliente',
      'setNewCliente',
      'setLimpiarCliente'
    ]),
    ...mapActions('contactos', ['setContacto', 'setLimpiarContacto']),

    CargarInfo(contacto) {
      console.log('cargar Info contacto', contacto.idcontacto)
      this.setContacto(contacto)
      this.getContacto
      //CLIENTES
      let cNumcli = this.getContacto.numcli.trim()
      console.log("cnumcli", cNumcli)
      if (cNumcli != '') {
        this.$http
          .get('v2/clientebynumcli/' + cNumcli)
          .then(response => {
            console.log('response numcli', response.data)
            if (response.data.error === null) {
              this.cliente = {
                numcli: response.data.result.numcli,
                nomcli: response.data.result.nomcli
              }
              this.setCliente(this.cliente)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },

    agregar() {
      //VALIDAR INFORMACION
      if (!this.validaInfo) {
        this.showSnack({
          text: 'Debes registrar el nombre, empresa y teléfono',
          color: 'orange',
          timeout: 3000
        })
        return
      }

      // this.getNewContacto()
      this.nomcont = this.getNewContacto

      this.numcli = this.getCliente.numcli

      var payload = {
        nomcont: this.nomcont,
        empresa: this.empresa,
        email: this.email,
        telefono: this.telefono,
        celular: this.celular,
        numcli: this.numcli,
        sucursal: this.sucursal,
        departamento: this.departamento
      }

      this.loading = true
      console.log('payload', payload)
      //MODO NUEVO
      if (this.nModo == 1) {
        this.$http
          .post('v2/insert.contacto', payload)
          .then(response => {
            this.showSnack({
              text: 'Contacto Agregada correctamente',
              color: 'success',
              timeout: 2000
            })
            this.$router.push({ name: 'catcontacto' })
            // setTimeout(() => this.$router.push({ name: 'catcontacto' }), 2000)

          })
          .catch(error => {
            console.log(error)
            this.showSnack({
              text: 'Problema para agregar contacto ' + error,
              color: 'error',
              timeout: 2000
            })
          })
          .finally(() => {
            this.setLimpiarContacto()
            this.setLimpiarCliente()
            this.loading = false
          })
      } else {
        // EDICION DE UNA CONTACTO
        this.$http
          .put('v2/update.contacto/' + this.idcontacto, payload)
          .then(response => {
            this.showSnack({
              text: 'Contacto Agregada correctamente',
              color: 'success'
            })

            setTimeout(() => this.$router.push({ name: 'catcontacto' }), 2000)
          })
          .catch(error => {
            this.showSnack({
              text: 'Problema para grabar contacto' + error.error,
              color: 'error'
            })
            console.log(error)
          })
          .finally(() => {
            this.setLimpiarContacto()
            this.setLimpiarCliente()
            this.loading = false
          })
      }
    },
    limpiarContacto() {
      this.nomcont = ''
      this.empresa = ''
      this.telefono = ''
      this.celular = ''
      this.email = ''
      this.idcontacto = 0
      this.sucursal = ''
      this.departamento = ''
    },

    regresar() {
      this.$router.push({ name: 'catcontacto' })
    },

    actualizarDatoscontactos() {
      console.log("actualizad wath getcontaco", this.getContacto.idcontacto)
      //Se encontró contacto poner en modo editar.
      if (Number(this.getContacto.idcontacto) > 0){
        this.nModo= 2
        this.titulo = 'Editar contacto'
      } else {
        this.nModo= 1
        this.titulo = 'Nuevo contacto'
      }
      
      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = this.getContacto.idcontacto
      this.nomcont = this.getContacto.nomcont
      this.email = this.getContacto.email
      this.numcli = this.getContacto.numcli
      this.numcont = this.getContacto.numcont
      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono
      this.departamento = this.getContacto.departamento
    },

    actualizarNomcont() {
      this.nomcont = this.getNewContacto
      console.log('actualizarnomcont', this.nomcont)
    },

    hora() {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }

      return hora + ':' + minuto + ':' + segundo
    },

    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>
.titulo {
  font-size: 35px;
}
</style>
