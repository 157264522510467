<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="ma-0 pa-0"
      >
        <v-card
          elevation="1"
          height="auto"
        >
          <v-toolbar
            dark
            height="40"
            dense
            flat
            color="teal"
            shaped
          >
            <v-toolbar-title>Historial </v-toolbar-title>
            <v-spacer />

            <!-- <v-btn
              align="right"
              color="primary"
              dark
              small
              class="ma-1"
              @click="regresar"
            >
              Cita<v-icon>mdi-reply</v-icon>
            </v-btn>


            <v-btn
              align="right"
              color="secondary"
              dark
              small
              class="ma-1"
              @click="agregar"
            >
              <v-icon>mdi-check-circle</v-icon>Tarea
            </v-btn> -->
          </v-toolbar>

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="(item, i) in items"
              :key="i"
              color="blue"
              :titulo="item.titulo"
              :subtitulo="item.subtitulo"
              :fecha="item.fecha"
              small
            >
              <v-row class="pt-1">
                <v-col cols="3">
                  <strong>{{ item.fecha }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ item.titulo }}</strong>
                  <div class="text-caption">
                    {{ item.subtitulo }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <!-- <v-btn
            class="ma-3"
            dark
            color="indigo"
          >
            Ver Citas
          </v-btn>

          <v-btn
            color="teal"
            dark
          >
            ver Actividades
          </v-btn> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
  
export default {
  data(){
    return{

      items: [
        {
          titulo: 'Demostracion',
          fecha:'3 Oct',
          subtitulo: 'Actvididad',
        },
        {
          titulo: 'Cita en zoom',
          fecha:'24 Sep',
          subtitulo: 'Cita',
        },
        {
          titulo: 'Nuevo contacto',
          fecha:'17 Sep',
          subtitulo: 'Registro de contacto',
        },
      ],

      obs:'',
		    //Historial
		    nomcont: '',
		    // empresa: '',
		    telefono: '',
		    empresa: '',
		    email: '',
		    celular: '',
		    sucursal: '',
		    departamento: '',
		    idcontacto: 0,
		    nModo: 1
    }
  },
	
  computed:{
	    // validaInfo(){
	    //   return  this.asunto   !== ''  &&
    // 			this.fechastart !== '' &&
    // 			this.hora !== '' &&
	    //           this.temas  !== ''
	    // },
	  },

  created(){

	    
  },

  methods:{
    // ...mapActions('citas',['addCita']),
    ...mapActions("snackbar", ["showSnack"]),


    CargarInfo(cita){
      console.log("cita", cita)

      this.asunto= cita.asunto
      this.temas = 	cita.temas
      this.lugar =	cita.lugar

      this.idcontacto=  cita.idcontacto
      this.idproy= 0
      this.idoportunidad= 0

      this.fechastart= cita.fechastart
      this.fechaend= cita.fechaend
					
      this.fecha = cita.fechastart
      this.date = cita.fechastart

      this.seledura=  cita.seledura
      this.numproy= cita.numproy
      this.empresa=  cita.empresa

    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
		    	 this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });

		    	console.log("FALLA EN LLENAR")
		        // this.snackbar = true ; this.color="orange"
		        // this.text = "Faltan llenar Datos. Nombre, email, telefono"
		      return
		    }


      console.log("fecha",this.fecha)
      this.fechaend = this.fecha + ' '+ this.hora
      this.fechastart = this.fecha + ' '+ this.hora

      var payload = {
        asunto: this.asunto,
        temas: 	this.temas,
        lugar: 	this.lugar,

        idcontacto:  this.idcontacto,
        idproy: 0,
        idoportunidad: 0,

        fechastart: this.fechastart,
        fechaend: this.fechaend,
					
        seledura:  this.seledura,
        numproy:  this.numproy,
        empresa:  this.empresa,
        sucursal:  this.sucursal,
        departamento: this.departamento
      }

      console.log("payload", payload)

	
	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.cita', payload).then(response =>{
			        this.showSnack({
		            text: "Cita Agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiarCita()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para agendar Cita."+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA CITA.
        this.$http.put('v2/update.cita' + this.idcita, payload).then(response =>{
          this.showSnack({
		            text: "Problema para agendar Cita."+ error,
		            color: "error",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'catcitas'}),2000);

        }).catch(error=>{
            	this.showSnack({
		            text: "Problema para agendar Cita."+ error.error,
		            color: "error",
		            timeout: 2000,
		          });
          console.log(error)
        })

      }


    },

    limpiarCita(){
      this.asunto = ''
      this.temas = ''
      this.lugar = ''

      this.idcontacto = 0
      this.idproy= 0
      this.idoportunidad= 0

      this.fechastart = this.fecha
      this.fechaend = this.fecha
					
      this.seledura= '30'
      this.numproy = ''
      this.empresa = ''
      this.sucursal= ''
      this.departamento= ''
    },

    regresar(){
				 this.$router.push({name:'catcontacto'})
    },

    hora(){
      var f =new Date();
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }
        
      return hora + ":" + minuto + ":" + segundo
    },
	  
  }
}
</script>